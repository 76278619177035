<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
      <div class="header-label col-lg-4 col-12">Campaign Reports</div>
      <div class="
              col-lg-8 col-12
              text-end
              d-flex
              justify-content-end
              custom-flex-cloumn-mob
            ">
      </div>
    </div>
    <div class="company-section-outer" style="height: calc(100vh - 141px);">
      <div class="row g-3">
        <div class="col-lg-5 col-md-5 col-12">
          <div class="feedback-chart-outer pb-3">
            <div class="d-flex justify-content-between">
              <div class="chart-label">Guest Click Report</div>
              <div class=""><button type="button" title="Refresh" class="btn custom-outline-view-btn mt-2 me-3"
                  @click="campaignreport({ ah1: this.routeParam })">
                  <i class="pi pi-refresh call-btn-color"></i>
                </button></div>
            </div>
            <div v-if="checkEmptyDataCampaign">
              <div style="position: relative;min-height: 431px;">
                <div v-if="!showloadergenderreport">
                  <Chart type="doughnut" :data="chartDataCampaign" :options="campaignOptions" responsive="true" />
                </div>
                <div v-if="showloadergenderreport">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div style="position: relative;min-height: 350px;" class="d-flex align-items-center justify-content-center">
                <div>
                  No data found
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12" v-if="logsstatusflag">
          <div class="card border-success shadow p-3 mb-5 bg-white rounded">
            <div class="card-body">
              <div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" v-for="msg  in chartDataValue" :key="msg">
                    <div class="d-flex justify-content-between"><span>{{ msg.label }}</span><span class="fw-bold">{{
                      msg.count
                    }}</span></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import ApiService from "../../../service/ApiService";
  import { useRoute } from "vue-router";
  export default {
    data() {
      return {
        chartDataCampaign: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
              hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            },
          ],
        },
        campaignOptions: {
          plugins: {
            legend: {
              labels: {
                color: "#495057",
              },
            },
          },
        },
        chartDataValue: [],
        ApiLoadingSwitch: false,
        checkEmptyDataCampaign: true,
        showloadergenderreport: false,
        logsstatusflag: false,
      };
    },
    ApiService: null,
    created() {
      this.ApiService = new ApiService();
    },
  
    mounted() {
      this.loading = true;
      this.showloadergenderreport = true;
      const route = useRoute();
      this.routeParam = route.params.campaignId;
      this.campaignreport({ ah1: this.routeParam });
    },
    beforeUnmount() {
      clearInterval(this.timer4);
    },
    methods: {
      campaignreport(item) {
        this.ApiService.campaignreport(item).then((data) => {
          if (data.success == true) {
            this.chartDataCampaign = {
              labels: data.labels,
              datasets: [
                {
                  data: data.data,
                  backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                  hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                },
              ],
            };
            this.chartDataValue = data.datatable;
            this.showloadergenderreport = false;
            this.logsstatusflag = true;
          } else {
            this.checkEmptyDataCampaign = false;
            this.showloadergenderreport = false;
            this.logsstatusflag = false;
          }
        });
      },
      switchLoadingStatus() {
        if (this.ApiLoadingSwitch == true) {
          this.timer4 = window.setInterval(() => {
            this.campaignreport({ ah1: this.routeParam });
          }, 10000);
        } else {
          clearInterval(this.timer4);
        }
      },
    },
  };
  </script>
  <style scoped>
  .feedback-chart-outer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
  }
  
  .feedback-chart-outer .chart-label {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #4a5463;
    text-align: left;
    line-height: 16px;
    padding: 16px;
  }
  
  .company-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 520px; */
  }
  
  .company-section-outer::-webkit-scrollbar {
    display: block;
  }
  
  .company-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
  }
  
  .company-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
  }</style>